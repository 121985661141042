<script setup lang="ts">
const error = useError()
const i18n = useI18n()
const currentRoute = useCurrentRoute()
const api = useApi()
const bugsnag = useBugsnag()

const requestedUrl = globalThis.location.pathname
const value = ref(0)

function incrementValue() {
  value.value += 0.01

  if (value.value < 1) {
    setTimeout(() => incrementValue(), 200)
  }
}

incrementValue()

if (error.value?.message?.includes('Page not found')) {
  ;(async () => {
    // if the url contains index.php, redirect to the same url without index.php
    if (requestedUrl.includes('/index.php')) {
      globalThis.location.href = requestedUrl.replace('/index.php', '')
      return
    }

    // get the requested slug
    const slug = currentRoute.path
      .toString()
      .replace('travel/', '')
      .replace('/---', '')
      .split('/')
      .slice(2)
      .join('/')

    const parameters = new URLSearchParams()

    for (const key in currentRoute.query) {
      parameters.append(key, (currentRoute.query[key] ?? '').toString())
    }

    // help
    if (
      [
        'hulp-nodig',
        'potrzebna-pomoc',
        'precisa-de-ajuda',
        'behover-hjalp',
        'brauchen-sie-hilfe',
        'need-help',
        'necesitas-ayuda',
        'tarvitsen-apua',
        'besoin-d-aide',
        'bisogno-di-aiuto',
      ].includes(slug)
    ) {
      navigateTo(getRoute(`help?${parameters.toString()}`))
      return
    }

    // my outspot
    if (
      [
        'mijn-aankopen',
        'moje-zakupy',
        'as-minhas-compras',
        'mina-kop',
        'meine-ankaufe',
        'my-purchases',
        'mis-compras',
        'omat-ostokset',
        'mes-achats',
        'i-miei-acquisti',
      ].includes(slug)
    ) {
      navigateTo(getRoute(`account/my-orders?${parameters.toString()}`))
      return
    }

    // doi confirmation
    if (
      [
        'bevestig-registratie',
        'potwierdź-rejestrację',
        'confirmar-registo',
        'bekrafta-registrering',
        'bestatigung-anmeldung',
        'confirm-registration',
        'confirmar-subscripcion',
        'vahvista-rekisteroityminen',
        'confirmation-inscription',
        'conferma-registrazione',
      ].includes(slug.split('/').shift() ?? '')
    ) {
      navigateTo(
        getRoute(`account/confirm?token=${slug.split('/').pop() ?? ''}`),
      )
      return
    }
    // unsubscription confirmation
    if (
      [
        'abmelden',
        'unsubscribe',
        'confirmar-registo',
        'cancelar-subscripcion',
        'peruuta-tilaus',
        'se-desinscrire',
        'disdetta-newsletter',
        'uitschrijven',
        'wypisz',
        'anular-subscricao',
        'avsluta-prenumeration',
      ].includes(slug.split('/').shift() ?? '')
    ) {
      navigateTo(getRoute(`account/unsubscribe?${parameters.toString()}`))
      return
    }

    // testlinks
    if (slug.includes('testproduct')) {
      try {
        const deal = (
          await api.deals.get(parseInt(slug.split('/').pop() ?? '0'))
        ).data.value

        if (deal?.slug) {
          navigateTo(
            getRoute(`deals/test/${deal.slug}?${parameters.toString()}`),
          )
          return
        }
      } catch (e) {
        console.error(e)
      }
    }

    // maybe it's a deal page
    try {
      const deal = (await api.deals.getBySlug(slug)).data.value

      if (deal?.slug) {
        navigateTo(getRoute(`deals/${deal.slug}?${parameters.toString()}`))
        return
      }
    } catch (e) {
      console.error(e)
    }

    // bail and fall back to old website
    bugsnag.notify(new Error('Page not found'), (event) =>
      event.addMetadata('request', currentRoute),
    )

    setTimeout(() => {
      parameters.append('site_version', 'new')

      let redirectUrl = `${i18n.t('route.fallback_domain')}${requestedUrl}?${parameters.toString()}`

      if (globalThis.location.href.includes('www2.')) {
        redirectUrl = redirectUrl.replace('www2.', 'www.')
      }

      globalThis.location.href = redirectUrl
    }, 100)
  })()
} else {
  console.log(error.value)
}

console.log('error-page')
</script>

<template>
  <ion-page>
    <os-row class="please-wait">
      <os-column>
        <span>{{ $t('label.please_wait') }}</span>
        <ion-progress-bar
          :value="value"
          :type="value >= 1 ? 'indeterminate' : 'determinate'"
          class="gutter-above"
        ></ion-progress-bar>
      </os-column>
    </os-row>
  </ion-page>
</template>

<style>
.please-wait {
  height: 100svh;
  width: 100svw;
  font-weight: bold;
}

.please-wait ion-progress-bar {
  width: 33svw;
  --background: var(--accent--transparent);
  --progress-background: var(--accent);
}
</style>
